import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Table, Row, Attributes, Expander, Tabs, InfoIcon, List, WarningIcon } from '../components';
import { deleteEntityCurl, deleteEntityJava, deleteEntityJavascript, deleteEntityNode, deleteEntityPerl, deleteEntityPython, deleteEntityPhp, deleteEntityRuby, deleteEntityGo, deleteEntityC, deleteEntityVB, deleteEntityGroovy, deleteEntityObjectiveC, deleteEntitySwift } from 'requestExamples/entities';
export const endpoints = [{
  param: 'PUT',
  value: '/nodes/entity/SmartVault.Accounting.Firm/{account_id}/SmartVault.Accounting.FirmToEmployee',
  subtitle: 'Add employee',
  link: '#create-an-employee'
}, {
  param: 'POST',
  value: '/nodes/entity/SmartVault.Accounting.Firm/{account_id}/SmartVault.Accounting.FirmToEmployee/{employee_id}',
  subtitle: 'Modify employee group membership',
  link: ' #edit-an-employee'
}, {
  param: 'DELETE',
  value: '/nodes/entity/SmartVault.Accounting.Firm/{account_id}/SmartVault.Accounting.FirmToEmployee/{employee_id}',
  subtitle: 'Delete employee',
  link: '#delete-an-employee'
}];
export const createEntity = [{
  param: 'PUT',
  value: '/nodes/entity/SmartVault.Accounting.Firm/{account_id}/SmartVault.Accounting.FirmToEmployee'
}];
export const updateEntity = [{
  param: 'POST',
  value: '/nodes/entity/SmartVault.Accounting.Firm/{account_id}/SmartVault.Accounting.FirmToEmployee/{employee_id}'
}];
export const deleteEntity = [{
  param: 'DELETE',
  value: '/nodes/entity/SmartVault.Accounting.Firm/{account_id}/SmartVault.Accounting.FirmToEmployee/{employee_id}'
}];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  endpoints,
  createEntity,
  updateEntity,
  deleteEntity,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "employees"
    }}>{`Employees`}</h1>
    <Row mdxType="Row">
	<div>
		<p>
			SmartVault's API allows the <a href="#create-an-employee">creation</a> of employees as entities for an
			account as well as the ability to <a href="#edit-an-employee">update</a> its group membership at any time
			and its <a href="#delete-an-employee">deletion</a>.<br />
		</p>
	</div>
	<Table title="Endpoints" rows={endpoints} mdxType="Table" />
    </Row>
    <br />
    <br />
    <br />
    <hr style={{
      "width": "50%"
    }} />
    <br />
    <br />

    <h2 {...{
      "id": "create-an-employee"
    }}>{`Create an employee`}</h2>
    <Row mdxType="Row">
	<div>
		<p>
			There are some limitations for entity creation based on the user permissions:
			<br />
			<br />
			<InfoIcon mdxType="InfoIcon" />
			Only admin level users can create employee entities.
			<br />
			<br />
		</p>
	</div>
	<Table title="Create an employee" rows={createEntity} mdxType="Table" />
    </Row>
    <br />
    <br />
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'account_id',
      type: 'string',
      description: "The account's alphanumerical identification."
    }]} mdxType="Attributes" />
    <br />
    <InfoIcon mdxType="InfoIcon" />
You can find more info on how to retrieve the account's id <a href="/api/entities/retrieve-an-entity#the-account-id" target="_blank">
	here
    </a>.
    <br />
    <br />
    <br />
    <p><strong parentName="p">{`Body parameters`}</strong></p>
    <hr></hr>
    <WarningIcon mdxType="WarningIcon" />
Even though some parameters are optional, make sure you use the ones you really want before submitting the request, as you
won't be able to edit anything of the employee, excepting the membership type, after its been created.
    <br />
    <br />
    <Expander title="Required fields" mdxType="Expander">
	<List values={[{
        title: 'entity_definition',
        value: 'Needs to be "SmartVault.Core.UserAssociation" when adding employees.'
      }, {
        title: 'address',
        value: 'For the "address" field inside "email_addresses" array; refers to the email of the employee being added.'
      }, {
        title: 'membership_type',
        value: "The type of membership for the employee related to permissions. Values can be:\r\nAdministrator.\r\nAccountEmployee: account employees can work with all clients in SmartVault. They have read, write, create and delete permissions on all folders in the client's container.\r\nAssignedEmployee: assigned employees can only work with clients to whom they are assigned. They have read, write and delete permissions on only their client's folders."
      }]} mdxType="List" />
    </Expander>
    <br />
    <Expander title="Optional fields" mdxType="Expander">
	<List values={[{
        title: 'FirstName',
        value: 'The first naew employee:me of the employee being added.'
      }, {
        title: 'MiddleName',
        value: 'The  middle name of the employee being added.'
      }, {
        title: 'LastName',
        value: 'The last name of the employee being added.'
      }, {
        title: 'Number',
        value: 'The phone number of the employee being added.'
      }]} mdxType="List" />
    </Expander>
    <br />
    <br />
    <p>{`Example of a request body for adding a new employee.`}</p>
    <Expander title="body request" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
   "entity": {
      "meta_data": {
         "entity_definition": "SmartVault.Core.UserAssociation"
      },
      "smart_vault": {
         "core": {
            "user_association": {
               "persons": [
                  {
                     "names": [
                        {
                           "FirstName": "New First Name",
                           "MiddleName": "New Middle Name",
                           "LastName": "New Last Name"
                        }
                     ],
                     "email_addresses": [
                        {
                           "address": "email@smartvault.com"
                        }
                     ],
                     "phone_numbers": [
                        {
                           "Number": "+1123456789"
                        }
                     ]
                  }
               ],
               "membership_type": "Administrator | AccountEmployee | AssignedEmployee",
            }
         }
      }
   }
}
`}</code></pre>
    </Expander>
    <br />
    <InfoIcon mdxType="InfoIcon" />For more information on how to assign a new employee of "assigned" type to a client, check <a href="/api/entities/clients#assign-employee-to-client" target="_blank">here</a>.
    <br />
    <br />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Will return the newly added employee object.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "name": "a0ULuqf-xESSGWoFugdavA",
        "createdOn": "2020-07-01T09:46:39-05:00",
        "modifiedOn": "2020-07-01T09:46:39-05:00",
        "uri": "/nodes/entity/SmartVault.Accounting.Firm/dZz67AeDg0GnvBRoNiJ_5w/SmartVault.Accounting.FirmToEmployee/a0ULuqf-xESSGWoFugdavA",
        "dav_uri": "/nodes/pth/SmartVault Account/Employees/email@smartvault.com",
        "your_permissions": 113
    }
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`You can get an error when trying to add a new employee if you are already at max number of employees for the account.`}</p>
    <Expander title="error object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": false,
        "error_code": -519962545,
        "error_text": "Adding this user would exceed the user limit for this account.",
        "instance_guid": "f247e488-93f2-49e4-91f1-172c7f3dd2f0",
        "status_code": 500
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />
    <br />

    <h2 {...{
      "id": "edit-an-employee"
    }}>{`Edit an employee`}</h2>
    <Row mdxType="Row">
	<div>
		<p>
			You can <span style={{
            "fontStyle": "italic"
          }}>only</span> edit an employee's membership type once it has been
			created.
			<br />
		</p>
	</div>
	<Table title="Update an employee" rows={updateEntity} mdxType="Table" />
    </Row>
    <br />
    <br />
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'account_id',
      type: 'string',
      description: "The account's alphanumerical identification."
    }, {
      name: 'employee_id',
      type: 'string',
      description: "The employee's alphanumerical identification."
    }]} mdxType="Attributes" />
    <br />
    <br />
    <p><strong parentName="p">{`Body parameters`}</strong></p>
    <hr></hr>
    <Expander title="Body parameters" mdxType="Expander">
	<List values={[{
        title: 'membership_type',
        value: "The type of membership for the employee related to permissions. Values can be:\r\nAdministrator.\r\nAccountEmployee: account employees can work with all clients in SmartVault. They have read, write, create and delete permissions on all folders in the client's container.\r\nAssignedEmployee: assigned employees can only work with clients to whom they are assigned. They have read. write and delete permissions on only their client's folders."
      }]} mdxType="List" />
    </Expander>
    <br />
    <br />
    <p>{`Example of a request body for updating the membership type an employee:`}</p>
    <Expander title="body request" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "query": false,
    "update": {
        "membership_type": "Administrator | AccountEmployee | AssignedEmployee"
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Will return the newly added employee object.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "name": "a0ULuqf-xESSGWoFugdavA",
        "createdOn": "2020-07-02T03:08:42-05:00",
        "modifiedOn": "2020-07-02T03:08:42-05:00",
        "uri": "/nodes/entity/SmartVault.Accounting.Firm/dZz67AeDg0GnvBRoNiJ_5w/SmartVault.Accounting.FirmToEmployee/a0ULuqf-xESSGWoFugdavA",
        "dav_uri": "/nodes/pth/SmartVault Account/Employees/email@smartvault.com",
        "your_permissions": 113
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />
    <br />
    <h2 {...{
      "id": "delete-an-employee"
    }}>{`Delete an employee`}</h2>

    <Row mdxType="Row">
	<div>
		<p>
			Deletes the entity on the specified path based on the account ID and the employee to delete ID.
			<br />
			<br />
			<InfoIcon mdxType="InfoIcon" />
			You cannot remove yourself from a vault.
		</p>
	</div>
	<Table title="Delete an employee" rows={deleteEntity} mdxType="Table" />
    </Row>
    <br />
    <br />
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'account_id',
      type: 'string',
      description: "The account's alphanumerical identification."
    }, {
      name: 'employee_id',
      type: 'string',
      description: "The employee's alphanumerical identification."
    }]} mdxType="Attributes" />
    <br />
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <Tabs items={[{
      tabTitle: 'cURL',
      tabInfo: deleteEntityCurl
    }, {
      tabTitle: 'Java',
      tabInfo: deleteEntityJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: deleteEntityJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: deleteEntityNode
    }, {
      tabTitle: 'Perl',
      tabInfo: deleteEntityPerl
    }, {
      tabTitle: 'Python',
      tabInfo: deleteEntityPython
    }, {
      tabTitle: 'PHP',
      tabInfo: deleteEntityPhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: deleteEntityRuby
    }, {
      tabTitle: 'Go',
      tabInfo: deleteEntityGo
    }, {
      tabTitle: 'C#',
      tabInfo: deleteEntityC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: deleteEntityVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: deleteEntityGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: deleteEntityObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: deleteEntitySwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Returns a default success object if the deletion goes well.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
  "error": {
    "success": true
  },
  "message": {}
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`Returns an `}<a parentName="p" {...{
        "href": "/api/errors#error-object"
      }}>{`error object`}</a>{` if the path to the employee doesn't exist.`}</p>
    <Expander title="error object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": false,
        "error_code": -520093684,
        "error_text": "Object not found.",
        "instance_guid": "693476f2-8971-450d-a7b6-287cc986f3df",
        "status_code": 404
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      